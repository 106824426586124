import React from 'react';
import { Card } from '../card';
import { Button } from '../button';
import { ImageCard } from '../imageCard';
import { CardContact } from '../cardContact';
import Footer from '../footer';
import './services.css';
import '../../App.css';
import servicesImg1 from '../../images/services-img-1.png';

function Services() {
    return (
        <>
            <div class="container-fluid section-margin services-section-1">
                <div className="container">

                    <h1>Services</h1>
                    <div class="row row-cols-1 row-cols-md-2 flex-wrap auto justify-content-center">
                        <div className='col-auto order-1'>
                            <Card cardIcon='none' cardStyle='card-outline' cardSize='card-860'>
                                <h5 class="card-title my-4">
                                    As your innovation partner, we collaborate with organizations to accelerate their R&D efforts through AI-powered research, XR development, and UX design.
                                </h5>
                                <p class="card-text">
                                    Our custom CIC Framework enables us to design and develop Proof of Concepts (POCs) that address business objectives, driving results and informing decisions. By outsourcing innovation research and POCs to DX Labs, you can tap into our methodologies, leverage technologies, and focus on your core competencies while we handle the heavy lifting.
                                </p>
                                <div className='row align-items-end mt-3 mt-lg-5'>
                                    <div className='col mt-lg-5'>
                                        <Button link='/research' buttonStyle='btn-primary'>Learn More</Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className='d-none d-lg-block col-auto order-0 order-lg-1 mb-4 mb-lg-0 d-flex justify-content-center'>
                            <ImageCard cardSize='card-450'>
                                <img style={{ width: 100 + '%' }} alt='Services' title='Services' className='' src={servicesImg1} />
                            </ImageCard>
                        </div>
                    </div>

                </div>
            </div>

            <div class="container-fluid section-margin services-section-2">
                <div className="container">

                    <div class="row auto justify-content-md-center">
                        <div class="px-4 d-flex flex-column">
                            <h2 className='my-4'>
                                Our Expertise Is Your Advantage
                            </h2>
                            <p className='mb-5'>
                                <span>The Power of Combined Solutions: </span>Our expertise in AI, AR, VR, and user experience design allows us to create immersive experiences that drive results, like AI-powered chatbots streamlining customer interactions or VR training simulations improving efficiency.
                            </p>

                            <span className='mb-5 mt-4'>Artificial Intelligence & AI</span>

                            <p className='mb-5'>
                                Use AI to uncover patterns and trends, automate tasks, and make data-driven decisions. Our methodologies help drive business outcomes through predictive analytics, natural language processing, and computer vision.
                            </p>

                            <div class="row auto justify-content-md-center">
                                <div className='grey-border-bottom my-3'></div>
                            </div>


                            <span className='mb-5 mt-4'>Augmented Reality</span>

                            <p className='mb-5'>
                                Elevate customer loyalty and boost sales with our AR capabilities, crafting interactive experiences that bridge the digital and physical realms to redefine marketing, sales, and training.
                            </p>

                            <div class="row auto justify-content-md-center">
                                <div className='grey-border-bottom my-3'></div>
                            </div>

                            <span className='mb-5 mt-4'>Virtual Reality:</span>

                            <p className='mb-5'>
                                Improve operational efficiency and employee retention with immersive VR experiences. Our VR development proficiency lets you prototype new concepts, train employees effectively, and transform customer interactions.
                            </p>

                            <div class="row auto justify-content-md-center">
                                <div className='grey-border-bottom my-3'></div>
                            </div>

                            <span className='mb-5 mt-4'>User Experience (UX):</span>

                            <p className='mb-5'>
                                Enhance efficiency and retain employees with immersive VR experiences that revolutionize your business. Our VR development proficiency empowers you to prototype new concepts, train employees efficiently, and transform customer interactions.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div class="container-fluid section-margin services-section-3">
                <div className="container">

                    <CardContact>
                        Empower Your Innovation Project Get In Touch!
                    </CardContact>

                </div>
            </div>

            <Footer />
        </>
    );
}

export default Services;