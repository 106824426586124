import React, { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Button } from './button';
import Logo from '../images/logo.png';
import './navbar.css';

function Navbar() {
  const [navOpen, setNavOpen] = useState(false);

  const hadleNav = () => setNavOpen((state) => !state);


  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
          <Link to='/' className='navbar-brand' onClick={navOpen ? hadleNav : ''}>
            <img alt='DX labs' title='DX labs' src={Logo} />
          </Link>
          <button onClick={hadleNav} class={navOpen ? "navbar-toggler" : "navbar-toggler collapsed"} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class={navOpen ? "collapse navbar-collapse justify-content-end show" : "collapse navbar-collapse justify-content-end"} id="navbarSupportedContent">
            <ul class="navbar-nav align-items-center">
              <li className='nav-item mx-2 py-2'>
                <Link to='/products' className='nav-links' onClick={hadleNav}>
                  Products
                </Link>
              </li>
              <li className='nav-item mx-2 py-2'>
                <Link to='/services' className='nav-links' onClick={hadleNav}>
                  Services
                </Link>
              </li>
              <li className='nav-item mx-2 py-2'>
                <Link to='/research' className='nav-links' onClick={hadleNav}>
                  Research & Methodologies
                </Link>
              </li>
              <li className='nav-item mx-2 py-2'>
                <Link to='/about' className='nav-links' onClick={hadleNav}>
                  Who We Are
                </Link>
              </li>
              <li className='nav-item mx-2 py-1'>
                <Button onClick={hadleNav} link='/contact' buttonStyle='btn-outline'>Contact Us</Button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
}

export default Navbar;
