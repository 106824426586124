import React from 'react';
import './button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn-primary', 'btn-outline'];

export const Button = ({ children, type, onClick, link, buttonStyle }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    if (link) {
        return (
            <Link to={link} className='btn-link'>
                <button onClick={onClick} type={type} className={`btn ${checkButtonStyle}`}>
                    {children}
                </button>
            </Link>
        );
    } else {
        return (
            <button onClick={onClick} type={type} className={`btn ${checkButtonStyle}`}>
                {children}
            </button>
        );
    }
}