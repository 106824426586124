import { React, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../button';
import Footer from '../footer';
import './contact.css';
import '../../App.css';

function Contact() {
    const [disabled, setDisabled] = useState(false);
    const [alertInfo, setAlertInfo] = useState({
        display: false,
        message: '',
        type: '',
    });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const toggleAlert = (message, type) => {
        setAlertInfo({ display: true, message, type });
        setTimeout(() => {
            setAlertInfo({ display: false, message: '', type: '' });
        }, 5000);
    };

    const onSubmit = async (data) => {
        const { name, email, message } = data;
        try {
            setDisabled(true);

            console.log(process.env.REACT_APP_SDMAIL_API_KEY);
            console.log('Name: ', name);
            console.log('Email: ', email);
            console.log('Message: ', message);

            toggleAlert('Contact submission was successful!', 'success');
        }
        catch (e) {
            console.error(e);
            toggleAlert('Uh oh. Something went wrong.', 'danger');
        }
        finally {
            setDisabled(false);
            reset();
        }



    };

    return (
        <>
            <div className="container-fluid section-margin contact-section-1">
                <div className="container">

                    {alertInfo.display && (
                        <div className={`alert alert-${alertInfo.type} alert-dismissible mt-5`} role='alert'>
                            {alertInfo.message}
                            <button type='button' className='btn-close' data-bs-dismiss='alert' aria-label='Close'
                                onClick={() =>
                                    setAlertInfo({ display: false, message: '', type: '' })
                                }
                            ></button>
                        </div>
                    )}
                    <h1>Get in Touch</h1>
                    <div className="row row-cols-1 row-cols-lg-2 auto justify-content-md-between">
                        <div className='px-3 px-md-5 mb-5 col-12 col-lg-5 d-flex flex-column'>
                            <h2 className='mb-4'>Are You Ready To Innovate With Purpose?</h2>
                            <p className='mb-5 d-none d-md-block'>
                                We specialize in AI, AR, VR, and user-centric design to create exceptional digital experiences. Our experts combine cutting-edge technologies with industry knowledge to help you navigate the digital landscape and unlock growth opportunities. Let's connect and explore how we can integrate advanced tools to achieve your goals.
                            </p>

                            <div className='mb-3 mt-5'>
                                <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <g clip-path="url(#clip0_322_1228)">
                                        <path d="M15.3532 19.9897C18.2844 20.0938 20.6463 17.0499 19.8404 14.2443C19.7133 13.8168 19.5982 13.2009 19.0742 13.1196C19.0742 13.1196 12.5703 12.095 12.5703 12.095C12.3504 12.0599 12.1253 12.1389 11.9738 12.3048C11.6368 12.6756 11.3701 13.0851 11.1756 13.5278C9.16153 12.6092 7.38392 10.833 6.46581 8.8177C6.99591 8.51489 7.94796 8.16914 7.89832 7.42272C7.89825 7.42266 6.87368 0.919094 6.87368 0.919094C6.79623 0.40045 6.17471 0.280025 5.76065 0.1557C4.18898 -0.256075 2.49893 0.203844 1.34946 1.35364C0.481664 2.22111 0.003533 3.37708 0.003533 4.60721C-0.183523 12.8773 7.08436 20.1607 15.3532 19.9897ZM2.29141 2.29559C3.16568 1.41107 4.45393 1.12363 5.61622 1.49839C5.61622 1.49838 6.5224 7.25385 6.5224 7.25385C6.0177 7.75691 4.65665 7.6546 4.95332 8.65084C6.00467 11.5165 8.47746 13.9884 11.3421 15.0399C11.7015 15.1834 12.1391 14.9534 12.2229 14.5745C12.3205 14.1744 12.4935 13.8043 12.7394 13.4702L18.4946 14.377C19.2267 16.4276 17.524 18.7281 15.3544 18.6574C11.6069 18.6575 8.08625 17.2009 5.43928 14.554C2.78581 11.9005 1.32865 8.36916 1.3358 4.60851C1.3358 3.73291 1.67537 2.91163 2.29141 2.29559Z" fill="#212121" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_322_1228">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                214.774.2390
                            </div>

                            <div className='d-flex'>
                                <div className='me-3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99734 0.625C6.28797 0.625 3.26172 3.76625 3.26172 7.6525C3.26172 8.78813 3.82797 10.32 4.65297 11.8975C6.47484 15.3825 9.51109 19.1425 9.51109 19.1425C9.62984 19.2894 9.80859 19.375 9.99734 19.375C10.1861 19.375 10.3648 19.2894 10.4836 19.1425C10.4836 19.1425 13.5198 15.3825 15.3423 11.8975C16.1673 10.32 16.7336 8.78813 16.7336 7.6525C16.7336 3.76625 13.7067 0.625 9.99734 0.625ZM9.99734 1.875C13.0336 1.875 15.4836 4.47188 15.4836 7.6525C15.4836 8.63688 14.9498 9.95062 14.2348 11.3181C12.9055 13.8606 10.9098 16.5531 9.99734 17.7375C9.08547 16.5531 7.08984 13.8606 5.76047 11.3181C5.04547 9.95062 4.51172 8.63688 4.51172 7.6525C4.51172 4.47188 6.96172 1.875 9.99734 1.875ZM10.028 3.75C7.81422 3.75 6.03922 5.44313 6.03922 7.5C6.03922 9.55688 7.81422 11.25 10.028 11.25C12.2411 11.25 14.0167 9.55688 14.0167 7.5C14.0167 5.44313 12.2411 3.75 10.028 3.75ZM10.028 5C11.5273 5 12.7667 6.10687 12.7667 7.5C12.7667 8.89313 11.5273 10 10.028 10C8.52859 10 7.28922 8.89313 7.28922 7.5C7.28922 6.10687 8.52859 5 10.028 5Z" fill="#212121" />
                                    </svg>
                                </div>
                                <div>
                                    151 W. Walters Street <br /> Lewisville TX 75057
                                </div>
                            </div>
                        </div>
                        <div className='px-3 px-md-5 col-12 col-lg-7 d-flex flex-column'>
                            <div className={`card-contact-2`}>
                                <div className={`card-body p-0`}>
                                    <form id='contact-form' onSubmit={handleSubmit(onSubmit)} method="POST" noValidate className="row justify-content-md-around g-3">
                                        <div className="col-auto">
                                            <input type="text" name='name' {...register('name', {
                                                required: { value: true, message: 'Please enter your name' }
                                            })} className="form-control mb-4" id="contactName" placeholder="Name" />
                                            {errors.name && <span className='errorMessage'>{errors.name.message}</span>}

                                            <input type="email" name='email' {...register('email', {
                                                required: { value: true, message: 'Please enter your e-mail' }
                                            })} className="form-control mb-4" id="contactEmail" placeholder="Email" />
                                            {errors.email && <span className='errorMessage'>{errors.email.message}</span>}


                                            <textarea name='message' {...register('message', {
                                                required: { value: true, message: 'Please enter a message' }
                                            })} className="form-control mb-4" id="contactMessage" placeholder='Message'></textarea>
                                            {errors.message && <span className='errorMessage'>{errors.message.message}</span>}

                                            <Button type='submit' buttonStyle='btn-primary'>Submit</Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    );
}

export default Contact;