import React from 'react';
import { Card } from '../card';
import { ImageCard } from '../imageCard';
import { CardContact } from '../cardContact';
import Footer from '../footer';
import './products.css';
import '../../App.css';
import productsImg1 from '../../images/products-img-1.png';
import imageArms from '../../images/products-img-arms.png';
import imageVital from '../../images/products-img-vital.png';

function Products() {
    return (
        <>
            <div class="container-fluid section-margin products-section-1">
                <div className="container">

                    <h1>Products</h1>

                    <div className='d-none d-lg-block'>
                        <div class="row row-cols-1 row-cols-md-2 flex-wrap auto justify-content-center">
                            <div className='col-auto order-1'>
                                <Card cardIcon='none' cardStyle='card-outline' cardSize='card-860'>
                                    <h5 class="card-title my-4">
                                        At DX Labs, a division of Tonic3, we specialize in creating innovative products and services to enhance digital experiences for businesses and consumers. Our expertise lies in Augmented Reality (AR), Artificial Intelligence (AI), Virtual Reality (VR), and User Experience (UX).
                                    </h5>
                                    <p class="card-text">
                                        We cultivate products by bridging the human experience with technology, translating findings into user-centric solutions. With a focus on delivering value and fostering connections, we establish expertise in our product areas, constantly pushing innovation boundaries.
                                    </p>
                                </Card>
                            </div>
                            <div className='col-auto order-0 order-lg-1 mb-4 mb-lg-0 d-flex justify-content-center'>
                                <ImageCard cardSize='card-450'>
                                    <img alt='Products' title='Products' className='rounded float-end' src={productsImg1} />
                                </ImageCard>
                            </div>
                        </div>
                    </div>

                    <div className='d-block d-lg-none'>
                        <div class="row row-cols-1 flex-wrap auto justify-content-center ">
                            <div className='col-auto order-1'>
                                <Card cardIcon='ar' cardStyle='card-outline' cardSize='card-860'>
                                    <h5 class="card-title my-4">
                                        ARMs
                                    </h5>
                                    <p class="card-text">
                                        ARms is a revolutionary platform designed to empower anyone to create custom AR experiences from our vast library of 3D models, making augmented reality accessible to all. Leveraging the latest web-based technology, ARms eliminates the need for downloads, allowing users to seamlessly access and engage with experiences by simply scanning a QR code. With unparalleled flexibility, this innovative platform can be tailored to suit both end-customer and business needs, offering endless possibilities for creative expression and effective communication.
                                    </p>
                                </Card>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="container-fluid section-margin products-section-2">
                <div className="container">

                    <div className='d-none d-lg-block'>
                        <div class="row auto justify-content-md-center">
                            <div class="px-4 d-flex flex-column">
                                <h2 id='arms' className='my-3'>
                                    <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none"><g clip-path="url(#clip0_166_53)"><circle cx="30" cy="30" r="30" fill="#C93600" /><path d="M24 27L30 30M24 27V33L30 36M24 27L30 24L36 27M30 30L36 27M30 30V36M30 36L36 33V27M26.1 43.5C22.74 43.5 21.06 43.5 19.776 42.846C18.6471 42.2708 17.7292 41.3529 17.154 40.224C16.5 38.94 16.5 37.26 16.5 33.9M43.5 33.9C43.5 37.26 43.5 38.94 42.846 40.224C42.2707 41.3529 41.3529 42.2708 40.224 42.846C38.94 43.5 37.26 43.5 33.9 43.5M33.9 16.5C37.26 16.5 38.94 16.5 40.224 17.154C41.3529 17.7292 42.2707 18.6471 42.846 19.776C43.5 21.06 43.5 22.74 43.5 26.1M26.1 16.5C22.74 16.5 21.06 16.5 19.776 17.154C18.6471 17.7292 17.7292 18.6471 17.154 19.776C16.5 21.06 16.5 22.74 16.5 26.1" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" /></g><defs><clipPath id="clip0_166_53"><rect width="60" height="60" fill="white" /></clipPath></defs></svg>
                                    ARms (Augmented Reality Management System)
                                </h2>
                                <p className='my-2'>
                                    ARms is a revolutionary platform designed to empower anyone to create custom AR experiences from our vast library of 3D models, making augmented reality accessible to all. Leveraging the latest web-based technology, ARms eliminates the need for downloads, allowing users to seamlessly access and engage with experiences by simply scanning a QR code. With unparalleled flexibility, this innovative platform can be tailored to suit both end-customer and business needs, offering endless possibilities for creative expression and effective communication.
                                </p>
                                <span className='my-5'>Try It Now: https://arms.tonic3.com/brand/login</span>
                                <img alt='ARms' title='ARms' className='img-fluid float-end mb-5' src={imageArms} />
                            </div>
                        </div>

                        <div class="row auto justify-content-md-center">
                            <div className='grey-border-bottom my-3'></div>
                        </div>

                        <div class="row auto justify-content-md-center mt-5">
                            <div class="px-4 d-flex flex-column">
                                <h2 id='vitalSense' className='my-3'>
                                    <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none"><g clip-path="url(#clip0_166_53)"><circle cx="30" cy="30" r="30" fill="#C93600" /><path d="M24 27L30 30M24 27V33L30 36M24 27L30 24L36 27M30 30L36 27M30 30V36M30 36L36 33V27M26.1 43.5C22.74 43.5 21.06 43.5 19.776 42.846C18.6471 42.2708 17.7292 41.3529 17.154 40.224C16.5 38.94 16.5 37.26 16.5 33.9M43.5 33.9C43.5 37.26 43.5 38.94 42.846 40.224C42.2707 41.3529 41.3529 42.2708 40.224 42.846C38.94 43.5 37.26 43.5 33.9 43.5M33.9 16.5C37.26 16.5 38.94 16.5 40.224 17.154C41.3529 17.7292 42.2707 18.6471 42.846 19.776C43.5 21.06 43.5 22.74 43.5 26.1M26.1 16.5C22.74 16.5 21.06 16.5 19.776 17.154C18.6471 17.7292 17.7292 18.6471 17.154 19.776C16.5 21.06 16.5 22.74 16.5 26.1" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" /></g><defs><clipPath id="clip0_166_53"><rect width="60" height="60" fill="white" /></clipPath></defs></svg>
                                    Vital Sense
                                </h2>
                                <p className='my-2'>
                                    VitalSense is an innovative system that enhances AR and VR applications by providing comprehensive data insights into user interactions. By integrating brainwave collection, biometric feedback, psychometric data, live video capture, custom metrics, and user experience methodologies with scientific approaches, VitalSense captures multidimensional data, offering unprecedented analysis capabilities. In addition, our experience evaluation can look at an individual's full experience through many lenses.
                                </p>
                                <span className='my-5'>Contact Us To Learn More...</span>
                                <img alt='Vital Sense' title='Vital Sense' className='img-fluid float-end mb-5' src={imageVital} />
                            </div>
                        </div>
                    </div>

                    <div className='d-block d-lg-none'>
                        <div class="d-flex flex-column justify-content-center align-items-center mb-5">
                            <div className='mobile-card d-flex justify-content-center align-items-center mb-4'>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                                    <path d="M16.3944 11.9739C14.6103 13.3261 12.9224 14.8006 11.3429 16.3869C3.89896 23.8308 0.129908 32.1253 2.92006 34.9181C5.71021 37.7083 14.0074 33.9366 21.4486 26.4953C23.035 24.9149 24.5095 23.2261 25.8616 21.4411" stroke="white" stroke-width="2.6174" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M25.8619 21.4411C30.2513 27.2675 32.039 32.7483 29.8718 34.9155C27.079 37.7083 18.7845 33.9366 11.3406 26.4953C3.90195 19.0488 0.132896 10.7569 2.92304 7.96412C5.09025 5.79953 10.5711 7.58722 16.3974 11.974" stroke="white" stroke-width="2.6174" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15.0854 21.4411C15.0854 21.7882 15.2233 22.1211 15.4688 22.3665C15.7142 22.6119 16.0471 22.7498 16.3941 22.7498C16.7412 22.7498 17.0741 22.6119 17.3195 22.3665C17.565 22.1211 17.7028 21.7882 17.7028 21.4411C17.7028 21.094 17.565 20.7611 17.3195 20.5157C17.0741 20.2703 16.7412 20.1324 16.3941 20.1324C16.0471 20.1324 15.7142 20.2703 15.4688 20.5157C15.2233 20.7611 15.0854 21.094 15.0854 21.4411ZM21.6551 10.0449C20.8306 9.90097 20.8306 8.72052 21.6551 8.57918C23.1113 8.32587 24.459 7.64465 25.5265 6.62237C26.594 5.60009 27.3329 4.28307 27.649 2.83921L27.6961 2.6115C27.8741 1.80011 29.031 1.79487 29.2142 2.60365L29.277 2.86801C29.6049 4.30547 30.3503 5.61398 31.4195 6.62917C32.4887 7.64436 33.834 8.32095 35.2865 8.57395C36.1136 8.7179 36.1136 9.90621 35.2865 10.0475C33.834 10.3005 32.4887 10.9771 31.4195 11.9923C30.3503 13.0075 29.6049 14.316 29.277 15.7535L29.2142 16.0178C29.031 16.8292 27.8741 16.824 27.6961 16.0126L27.6437 15.7849C27.3277 14.341 26.5888 13.024 25.5213 12.0017C24.4538 10.9795 23.1061 10.2982 21.6499 10.0449H21.6551Z" stroke="white" stroke-width="2.6174" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Vital Sense
                            </div>
                            <div className='mobile-card d-flex justify-content-center align-items-center'>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="44" height="27" viewBox="0 0 44 27" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7899 0H32.3697C34.5159 0 36.5743 0.852593 38.0919 2.37022C39.6095 3.88784 40.4621 5.94618 40.4621 8.09243V18.8823C40.4621 21.0286 39.6095 23.0869 38.0919 24.6045C36.5743 26.1222 34.5159 26.9748 32.3697 26.9748H31.3069C29.7084 26.9751 28.1456 26.502 26.8156 25.6152L24.5713 24.1181C23.6853 23.5278 22.6445 23.2128 21.5798 23.2128C20.5152 23.2128 19.4743 23.5278 18.5883 24.1181L16.344 25.6152C15.0141 26.502 13.4512 26.9751 11.8527 26.9748H10.7899C8.64368 26.9748 6.58535 26.1222 5.06772 24.6045C3.5501 23.0869 2.69751 21.0286 2.69751 18.8823V8.09243C2.69751 5.94618 3.5501 3.88784 5.06772 2.37022C6.58535 0.852593 8.64368 0 10.7899 0ZM10.7899 2.69748C9.3591 2.69748 7.98687 3.26587 6.97512 4.27762C5.96338 5.28937 5.39498 6.6616 5.39498 8.09243V18.8823C5.39498 20.3132 5.96338 21.6854 6.97512 22.6971C7.98687 23.7089 9.3591 24.2773 10.7899 24.2773H11.8527C12.9184 24.2775 13.9603 23.9621 14.8469 23.3709L17.0912 21.8738C18.4205 20.9877 19.9823 20.5149 21.5798 20.5149C23.1773 20.5149 24.7391 20.9877 26.0684 21.8738L28.3127 23.3709C29.1993 23.9621 30.2412 24.2775 31.3069 24.2773H32.3697C33.8005 24.2773 35.1727 23.7089 36.1845 22.6971C37.1962 21.6854 37.7646 20.3132 37.7646 18.8823V8.09243C37.7646 6.6616 37.1962 5.28937 36.1845 4.27762C35.1727 3.26587 33.8005 2.69748 32.3697 2.69748H10.7899Z" fill="white" />
                                    <path d="M0 10.7899H2.69747V18.8823H0V10.7899ZM40.4621 10.7899H43.1595V18.8823H40.4621V10.7899ZM17.5336 13.4874C17.8913 13.4874 18.2343 13.6295 18.4873 13.8824C18.7402 14.1354 18.8823 14.4784 18.8823 14.8361C18.8823 15.1938 18.7402 15.5369 18.4873 15.7898C18.2343 16.0428 17.8913 16.1849 17.5336 16.1849H10.7899C10.4322 16.1849 10.0891 16.0428 9.83619 15.7898C9.58325 15.5369 9.44115 15.1938 9.44115 14.8361C9.44115 14.4784 9.58325 14.1354 9.83619 13.8824C10.0891 13.6295 10.4322 13.4874 10.7899 13.4874H17.5336ZM32.3697 13.4874C32.7274 13.4874 33.0704 13.6295 33.3234 13.8824C33.5763 14.1354 33.7184 14.4784 33.7184 14.8361C33.7184 15.1938 33.5763 15.5369 33.3234 15.7898C33.0704 16.0428 32.7274 16.1849 32.3697 16.1849H25.626C25.2683 16.1849 24.9252 16.0428 24.6723 15.7898C24.4193 15.5369 24.2772 15.1938 24.2772 14.8361C24.2772 14.4784 24.4193 14.1354 24.6723 13.8824C24.9252 13.6295 25.2683 13.4874 25.626 13.4874H32.3697Z" fill="white" />
                                </svg>
                                TONI
                            </div>
                        </div>

                        <div class="d-flex flex-column justify-content-center align-items-center mb-5">
                            <div className='orange-line-y'></div>
                        </div>

                        <div class="row row-cols-1 flex-wrap auto justify-content-center mb-5">
                            <div className='d-flex flex-column mb-5'>
                                <h3 className='mb-4'>
                                    Our Approach
                                </h3>
                                <p>
                                    At DX Labs, we harness the power of science and innovation to drive meaningful solutions through our CIC Framework (Concept, Iterate, Consolidate). By combining cutting-edge AI research with human-centered design principles, we bring rigor and discipline to the ideation, creation, and building process. Our approach is guided by a user-centric focus, value-driven KPIs, and a commitment to product excellence.
                                </p>
                            </div>

                            <div className='d-flex flex-column mt-5 mb-5'>
                                <h3 className='mb-4'>
                                    Our Innovation Engine
                                </h3>
                                <p>
                                    At DX Labs, our approach to innovation begins with conducting in-depth research on emerging technologies like augmented reality, virtual reality, artificial intelligence, and extended reality. As active participants within the communities and ecosystems surrounding these fields, we stay ahead of the curve by driving conversations, sharing knowledge, and collaborating with experts. By leveraging this cutting-edge expertise, we explore potential applications for businesses and develop Proof of Concepts (POCs) that address specific business objectives and drive tangible value. Our team focuses on researching and experimenting with these technologies to identify how they can be used to create innovative solutions that solve real-world problems and drive business outcomes.
                                </p>
                            </div>
                        </div>

                        <div class="row row-cols-1 flex-wrap auto justify-content-center mb-5">
                            <div className='col-auto mb-5'>
                                <Card cardIcon='cic' cardStyle='card-primary' cardSize='card-860'>
                                    <h5 class="card-title my-4">
                                        The CIC Framework:
                                    </h5>
                                    <p class="card-text">
                                        Our custom innovation model, the CIC Framework, drives our ideation and development process. Grounded in rigorous research and informed by data-driven insights, this framework consists of three stages: Concept, Iterate, and Consolidate. In the Concept stage, we conceive novel concepts based on user research and technology trends, laying the foundation for innovative solutions.
                                        <br />
                                        <br />
                                        We then iterate and optimize these concepts through rapid prototyping and defining quadrants of delivery aligned with automated and manual user testing, ensuring seamless integration with business objectives. Finally, in the Consolidate stage, we consolidate our learnings and resources to build and test out the POC, validating the early stages of the hypothesis against previously defined metrics and KPIs, enabling us to deliver innovative solutions that drive tangible results.
                                    </p>
                                </Card>
                            </div>
                            <div className='col-auto'>
                                <Card cardIcon='inovation' cardStyle='card-primary' cardSize='card-860'>
                                    <h5 class="card-title my-4">
                                        Value-Driven Innovation:
                                    </h5>
                                    <p class="card-text">
                                        At DX Labs, we prioritize value-driven objectives and Key Performance Indicators (KPIs) throughout our innovation process. Powered by cutting-edge AI capabilities, we harness iterative learning from granular insights from user feedback and analytics to continuously optimize solutions, ensuring they meet the evolving needs of businesses and their customers in today's fast-paced market.
                                        <br />
                                        <br />
                                        By aligning our efforts with specific business goals and KPIs, such as time-to-market, customer engagement rates, revenue growth, and return on investment (ROI), we ensure that our POCs accelerate revenue growth, enhance customer satisfaction, and drive tangible benefits to our clients.
                                    </p>
                                </Card>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="container-fluid section-margin products-section-3">
                <div className="container">

                    <CardContact>
                        Empower Your Innovation Project Get In Touch!
                    </CardContact>

                </div>
            </div>

            <Footer />
        </>
    );
}

export default Products;