import React from 'react';
import './imageCard.css';

const SIZES = ['card-190', 'card-190x190', 'card-450'];

export const ImageCard = ({ children, cardSize }) => {
    const checkCardSize = SIZES.includes(cardSize) ? cardSize : SIZES[0];

    return (
        <div className={`image-card ${checkCardSize}`}>
            {children}
        </div>
    );
}