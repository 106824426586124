import React, { useState, useEffect } from 'react';
import { Card } from '../card';
import { Button } from '../button';
import { ImageCard } from '../imageCard';
import { CardContact } from '../cardContact';
import { Link } from 'react-router-dom';
import Footer from '../footer';
import useScreenSize from '../../useScreenSize';
import './about.css';
import '../../App.css';
import aboutImg1 from '../../images/about-img-1.jpeg';
import aboutImg1Mb from '../../images/about-img-1-mb.jpeg';
import imgPablo from '../../images/about-pablo.png';
import imgPreston from '../../images/about-preston.png';

function About() {
    const screenSize = useScreenSize();
    const [about, setAbout] = useState();
    useEffect(() => {
        if (screenSize.width > 991) {
            setAbout(aboutImg1);
        } else {
            setAbout(aboutImg1Mb);
        }
    });
    return (
        <>
            <div className="container-fluid section-margin about-section-1">
                <div className="container">

                    <h1>Who we are</h1>
                    <div className="row row-cols-1 row-cols-md-2 flex-wrap auto justify-content-center">
                        <div className='col-auto order-1'>
                            <Card cardIcon='none' cardStyle='card-outline' cardSize='card-860'>
                                <h5 className="card-title my-4">
                                    Since 2023, Tonic3 has advanced digital experiences through our DX Labs initiative, focusing on AR, VR, and AI technologies at the crossroads of UX.
                                </h5>
                                <p className="card-text">
                                    Our parent company has been committed to enhancing user engagement and digital solutions since 1995, supporting large organizations in developing websites, applications, and customer support teams. This strategic shift emphasizes our dedication to pushing the boundaries of traditional digital landscapes with innovative technologies.
                                </p>
                                <div className='row align-items-end mt-3 mt-lg-5'>
                                    <div className='col mt-lg-5'>
                                        <Button link='/about' buttonStyle='btn-primary'>Learn More</Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className='col-auto order-0 order-lg-1 mb-4 mb-lg-0 d-flex justify-content-center'>
                            <ImageCard cardSize='card-450'>
                                <img style={{ width: 100 + '%' }} alt='About' title='About' className='' src={about} />
                            </ImageCard>
                        </div>
                    </div>

                </div>
            </div>

            <div className='container-fluid section-margin about-section-2'>
                <div className="container">

                    <h2>Our Team Leaders</h2>
                    <div className='row'>
                        <div className="col-auto">
                            <ImageCard cardSize='card-190x190'>
                                <img src={imgPreston} className="img-fluid rounded-start" alt='Preston McCauley - Director Of Innovation Labs' title='Preston McCauley - Director Of Innovation Labs' />
                            </ImageCard>
                        </div>
                        <div className="col-auto py-3 py-lg-5">
                            <h5 className="about-section-2-title">Preston McCauley</h5>
                            <p className="about-section-2-text">Director Of Innovation Labs</p>
                            <div className='row'>
                                <div className='col-auto'>
                                    <Link to='preston-x'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                            <g clip-path="url(#clip0_322_436)">
                                                <path d="M34.0703 27.1382C34.0703 30.9509 30.9509 34.0703 27.1382 34.0703H6.86179C3.0491 34.0703 -0.0703125 30.9509 -0.0703125 27.1382V6.86179C-0.0703125 3.04918 3.0491 -0.0703125 6.86179 -0.0703125H27.1382C30.9509 -0.0703125 34.0703 3.04918 34.0703 6.86179V27.1382Z" fill="#212121" />
                                                <path d="M19.0817 15.5738L25.7738 7H23.8354L18.1128 14.3323L12.3897 7H6L14.9177 18.4256L8.22504 27H10.1634L15.8866 19.6671L21.6103 27H28L19.0817 15.5738ZM9.15098 8.55418H11.6639L24.8484 25.4457H22.3355L9.15098 8.55418Z" fill="#F0F0F1" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_322_436">
                                                    <rect width="34" height="34" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </Link>
                                </div>
                                <div className='col-auto'>
                                    <Link to='preston-in'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                            <g clip-path="url(#clip0_322_440)">
                                                <path d="M30.6 0H3.4C1.53 0 0 1.53 0 3.4V30.6C0 32.47 1.53 34 3.4 34H30.6C32.47 34 34 32.47 34 30.6V3.4C34 1.53 32.47 0 30.6 0ZM10.2 28.9H5.1V13.6H10.2V28.9ZM7.65 10.71C5.95 10.71 4.59 9.35 4.59 7.65C4.59 5.95 5.95 4.59 7.65 4.59C9.35 4.59 10.71 5.95 10.71 7.65C10.71 9.35 9.35 10.71 7.65 10.71ZM28.9 28.9H23.8V19.89C23.8 18.5301 22.61 17.34 21.25 17.34C19.89 17.34 18.7 18.5301 18.7 19.89V28.9H13.6V13.6H18.7V15.64C19.55 14.28 21.42 13.26 22.95 13.26C26.18 13.26 28.9 15.98 28.9 19.21V28.9Z" fill="#212121" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_322_440">
                                                    <rect width="34" height="34" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </Link>
                                </div>
                                <div className='col-auto'>
                                    <Link to='preston-mail'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" fill="none">
                                            <rect width="35" height="34" rx="3" fill="#212121" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.13176 24.2815V12.7974L16.6744 21.2879C16.9016 21.4899 17.1942 21.6014 17.4973 21.6016C17.8004 21.6018 18.0931 21.4906 18.3205 21.2889L27.8684 12.825V24.2815H7.13176ZM25.8178 11.3043H9.21203L17.5 18.6785L25.8178 11.3043ZM30.357 24.6344V10.9517C30.3562 10.3813 30.1309 9.83444 29.7302 9.43102C29.3296 9.02759 28.7864 8.80053 28.2197 8.79959H6.78057C6.21383 8.80038 5.67054 9.0274 5.26988 9.43086C4.86921 9.83431 4.64388 10.3813 4.64332 10.9517V24.6344C4.64391 25.2049 4.86923 25.7518 5.26989 26.1553C5.67054 26.5588 6.21381 26.7859 6.78057 26.7868H28.2197C28.7864 26.7857 29.3295 26.5586 29.7301 26.1553C30.1307 25.7519 30.3561 25.2047 30.357 24.6344Z" fill="white" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mt-3 mx-lg-4'>
                            <p>
                                Preston McCauley is the Director of Emerging Technologies at Tonic3 and an influential leader in AI, AR/VR, and UX. With over 25 years of experience in technology and design, Preston has consistently stayed ahead of technological trends, contributing significantly to the evolution of immersive technologies. He spearheads innovative strategies at Tonic3, utilizing AI, AR, VR, and UX to create transformative solutions that enhance user experiences and provide significant competitive advantages. His expertise in AI algorithms, machine learning, data science, and immersive technologies enables him to conceptualize and develop groundbreaking products that redefine user interactions.
                            </p>
                            <p>
                                Beyond his role at Tonic3, Preston is actively involved in academic and community leadership. He co-founded the UX program at SMU Cape in Dallas and the Dallas Immersive Design & Development Group. He also teaches AR/VR principles courses and is developing new educational content focusing on Learning AI and LLMs. His commitment to fostering talent and promoting growth among his team members is evident in his mentorship and the collaborative environment he nurtures. Preston's innovative approaches in AR and VR have led to several patents that merge spatial computing with AI, showcasing his ability to translate cutting-edge research into impactful real-world applications.
                            </p>
                            <p>
                                Overall, Preston McCauley's career is marked by a visionary approach to integrating AI and immersive technologies. He is a driving force in the industry, known for his ability to lead high-performing teams and develop innovative solutions that push the boundaries of technology and design. His work advances the field and sets new standards, making him a pivotal figure in the technological landscape.
                            </p>
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className="col-auto">
                            <ImageCard cardSize='card-190x190'>
                                <img src={imgPablo} className="img-fluid rounded-start" alt='Pablo Sigler - Acting CEO' title='Pablo Sigler - Acting CEO' />
                            </ImageCard>
                        </div>
                        <div className="col-auto py-3 py-lg-5">
                            <h5 className="about-section-2-title">Pablo Sigler</h5>
                            <p className="about-section-2-text">Acting CEO</p>
                            <div className='row'>
                                <div className='col-auto'>
                                    <Link to='pablo-in'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                            <g clip-path="url(#clip0_322_440)">
                                                <path d="M30.6 0H3.4C1.53 0 0 1.53 0 3.4V30.6C0 32.47 1.53 34 3.4 34H30.6C32.47 34 34 32.47 34 30.6V3.4C34 1.53 32.47 0 30.6 0ZM10.2 28.9H5.1V13.6H10.2V28.9ZM7.65 10.71C5.95 10.71 4.59 9.35 4.59 7.65C4.59 5.95 5.95 4.59 7.65 4.59C9.35 4.59 10.71 5.95 10.71 7.65C10.71 9.35 9.35 10.71 7.65 10.71ZM28.9 28.9H23.8V19.89C23.8 18.5301 22.61 17.34 21.25 17.34C19.89 17.34 18.7 18.5301 18.7 19.89V28.9H13.6V13.6H18.7V15.64C19.55 14.28 21.42 13.26 22.95 13.26C26.18 13.26 28.9 15.98 28.9 19.21V28.9Z" fill="#212121" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_322_440">
                                                    <rect width="34" height="34" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </Link>
                                </div>
                                <div className='col-auto'>
                                    <Link to='pablo-mail'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" fill="none">
                                            <rect width="35" height="34" rx="3" fill="#212121" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.13176 24.2815V12.7974L16.6744 21.2879C16.9016 21.4899 17.1942 21.6014 17.4973 21.6016C17.8004 21.6018 18.0931 21.4906 18.3205 21.2889L27.8684 12.825V24.2815H7.13176ZM25.8178 11.3043H9.21203L17.5 18.6785L25.8178 11.3043ZM30.357 24.6344V10.9517C30.3562 10.3813 30.1309 9.83444 29.7302 9.43102C29.3296 9.02759 28.7864 8.80053 28.2197 8.79959H6.78057C6.21383 8.80038 5.67054 9.0274 5.26988 9.43086C4.86921 9.83431 4.64388 10.3813 4.64332 10.9517V24.6344C4.64391 25.2049 4.86923 25.7518 5.26989 26.1553C5.67054 26.5588 6.21381 26.7859 6.78057 26.7868H28.2197C28.7864 26.7857 29.3295 26.5586 29.7301 26.1553C30.1307 25.7519 30.3561 25.2047 30.357 24.6344Z" fill="white" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mt-3 mx-lg-4'>
                            <p>
                                Pablo is responsible for leading the company's Board of Directors and ensuring that we adhere to the defined strategy. His current focus is fostering our company's growth. As part of the Board, he defines strategic plans and secures the necessary resources to execute those plans.
                            </p>
                            <p>
                                He founded W3 in 1995, which was rebranded as Tonic3 in 2019. During that time, he participated in several entrepreneurship ventures related to digital solutions, advertising, hospitality, and the restaurant business.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid section-margin about-section-3">
                <div className="container">

                    < CardContact>
                        Empower Your Innovation Project Get In Touch!
                    </CardContact>

                </div>
            </div>

            <Footer />
        </>
    );
}

export default About;