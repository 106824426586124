import React from 'react';
import { Card } from '../card';
import { ImageCard } from '../imageCard';
import { CardContact } from '../cardContact';
import Footer from '../footer';
import './research.css';
import '../../App.css';
import researchImg1 from '../../images/research-img-1.png';
import researchImg2 from '../../images/research-img-2.png';
import researchImg3 from '../../images/research-img-3.png';
import researchImg4 from '../../images/research-img-4.png';
import researchImg5 from '../../images/research-img-5.png';

function Research() {
    return (
        <>
            <div class="container-fluid section-margin research-section-1">
                <div className="container">

                    <div className='d-none d-lg-block'>
                        <h1>Research & Methodology</h1>
                        <div class="row row-cols-1 row-cols-md-2 flex-wrap auto justify-content-center">
                            <div className='col-auto order-1'>
                                <Card cardIcon='none' cardStyle='card-outline' cardSize='card-860'>
                                    <h5 class="card-title my-4">
                                        At DX Labs, a division of Tonic3, we specialize in creating innovative products and services that aim to enhance digital experiences for businesses and consumers. Our expertise lies in the fields of Augmented Reality (AR), Artificial Intelligence (AI), Virtual Reality (VR), and User Experience (UX), as well as their intersections.
                                    </h5>
                                    <p class="card-text">
                                        We cultivate products that are steered by our mastery in bridging the human experience with cutting-edge technology. Our approach goes beyond simply conducting research; we are committed to translating our findings into tangible, user-centric solutions. With a focus on delivering value and fostering meaningful connections, we strive to establish a solid foundation of expertise in our respective product areas, constantly pushing the boundaries of innovation.
                                    </p>
                                </Card>
                            </div>
                            <div className='col-auto order-0 order-lg-1 mb-4 mb-lg-0 d-flex justify-content-center'>
                                <ImageCard cardSize='card-450'>
                                    <img style={{ width: 100 + '%' }} alt='Research & Methodology' title='Research & Methodology' className='' src={researchImg1} />
                                </ImageCard>
                            </div>
                        </div>
                    </div>

                    <div className='d-block d-lg-none'>
                        <h1>Research &<br /> Methodology</h1>
                        <div class="row row-cols-1 flex-wrap auto justify-content-center ">
                            <div className='col-auto order-1'>
                                <h5 class="card-title my-4">
                                    Introduction to the CIC Model
                                </h5>
                                <p class="card-text">
                                    At DX Labs, we believe that innovation is not just about technology, but about driving tangible results for your business. That's why we've developed the CIC Framework, a structured approach to innovation that combines cutting-edge AI research with human-centered design principles. Our CIC model is designed to help you achieve breakthroughs and stay ahead of the competition by providing a clear roadmap for success.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="container-fluid section-margin research-section-2">
                <div className="container">

                    <div className='d-none d-lg-block'>
                        <div class="row auto justify-content-md-center">
                            <div class="px-4 mb-5 d-flex flex-column">
                                <h2 className='my-4'>
                                    Connect Human Experience To The Latest Technologies
                                </h2>
                                <p className='mb-5'>
                                    <span>The Power of Combined Solutions:  </span>Our deep understanding of artificial intelligence, augmented reality, virtual reality, and user experience design enables us to unlock profound insights, driving transformative innovation. Together, we can create immersive experiences that drive actual results, such as AI-powered chatbots streamlining customer interactions or VR training simulations improving operational efficiency, by seamlessly integrating our expertise in these complex fields.
                                </p>
                            </div>
                            <div class="px-4 mb-5 d-flex flex-column">
                                <Card cardIcon='none' cardStyle='card-outline' cardSize='card-full'>
                                    <h5 class="card-title my-4">
                                        <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none"><g clip-path="url(#clip0_322_1068)"><circle cx="30" cy="30" r="30" fill="#C93600" /><g clip-path="url(#clip1_322_1068)"><path d="M43.5536 39.6769L38.6374 34.7607C38.6373 34.7605 38.6372 34.7605 38.6371 34.7603C38.6369 34.7602 38.6369 34.7602 38.6367 34.76L36.8794 33.0026C36.2857 32.4089 35.3196 32.4087 34.7257 33.0026L34.5103 33.218L33.7681 32.4759C35.2221 30.7325 36.0982 28.4913 36.0982 26.049C36.0981 20.508 31.5901 16 26.049 16C20.5079 16 16 20.508 16 26.049C16 31.5901 20.5079 36.0981 26.049 36.0981C28.4912 36.0981 30.7325 35.222 32.4758 33.7681L33.218 34.5102L33.0025 34.7257C32.4089 35.3194 32.4089 36.2855 33.0026 36.8793L39.6768 43.5535C40.2719 44.1487 41.2354 44.1489 41.8306 43.5536L43.5536 41.8306C44.1474 41.2369 44.1474 40.2708 43.5536 39.6769ZM26.049 34.2706C21.5156 34.2706 17.8274 30.5824 17.8274 26.049C17.8274 21.5157 21.5156 17.8275 26.049 17.8275C30.5824 17.8275 34.2706 21.5157 34.2706 26.049C34.2706 30.5824 30.5824 34.2706 26.049 34.2706ZM34.5102 35.8025L35.8025 34.5103L36.6987 35.4065L35.4065 36.6987L34.5102 35.8025ZM40.7537 42.046L36.6987 37.9909L37.9909 36.6987L42.046 40.7537L40.7537 42.046Z" fill="white" /><path d="M26.0495 19.655C25.5449 19.655 25.1357 20.0641 25.1357 20.5688C25.1357 21.0735 25.5449 21.4826 26.0495 21.4826C28.5676 21.4826 30.6162 23.531 30.6162 26.049C30.6162 26.5536 31.0253 26.9628 31.53 26.9628C32.0346 26.9628 32.4437 26.5536 32.4437 26.049C32.4437 22.5233 29.5753 19.655 26.0495 19.655Z" fill="white" /></g></g><defs><clipPath id="clip0_322_1068"><rect width="60" height="60" fill="white" /></clipPath><clipPath id="clip1_322_1068"><rect width="28" height="28" fill="white" transform="translate(16 16)" /></clipPath></defs></svg>
                                        Designing A Framework Built For AI & Innovation
                                    </h5>
                                    <p class="card-text">
                                        The CIC model revolutionizes the approach to innovation by harnessing the combined powers of artificial intelligence and human expertise, delivering a fast track to developing proof-of-concept (POC) products.
                                        <br />
                                        <br />
                                        This pioneering framework is the cornerstone of agility and efficiency in the modern innovation landscape, offering a seamless method to evolve initial ideas into products poised for market success. At its core, the CIC model fosters a dynamic synergy between AI's predictive capabilities and the creative problem-solving prowess of human teams.
                                    </p>
                                </Card>
                            </div>
                            <div class="px-4 mb-5 d-flex flex-column">
                                <h2 className='my-4'>
                                    The CIC Innovation Model.
                                </h2>
                                <img alt='CIC Innovation Model' title='CIC Innovation Model' className='img-fluid' src={researchImg2} />
                            </div>

                            <div class="row auto justify-content-md-center mb-5">
                                <div className='grey-border-bottom my-3'></div>
                            </div>

                            <div class="px-4 mb-5 d-flex">
                                <div className='row'>
                                    <div className='col-7 d-flex justify-content-md-between align-items-center'>
                                        <div>
                                            <h3 className='mb-5'>
                                                The Concept Phase
                                            </h3>
                                            <p>
                                                The CIC model launches your innovation journey with laser focus. It uncovers untapped market opportunities, verifies the power of your data assets, and establishes clear metrics for success. This phase ensures that every step forward is backed by a strategic roadmap designed to maximize impact and minimize risk.
                                                <br />
                                                <br />
                                                <span>
                                                    Pinpoint Market Need: Identify genuine customer pain points and validate the potential for your solution.
                                                </span>
                                                <br />
                                                <br />
                                                <span>
                                                    Assess Data Readiness: Determine if your existing data can power the innovation and plan for necessary upgrades.
                                                </span>
                                                <br />
                                                <br />
                                                <span>
                                                    Define KPIs: Establish measurable goals to track progress and make informed decisions throughout the innovation process.
                                                </span>
                                            </p>
                                        </div>
                                        <div class='section-2-divider'></div>
                                    </div>
                                    <div className='col-4 position-relative'>
                                        <img alt='The Concept Phase' title='The Concept Phase' className='img-fluid' src={researchImg3} />
                                    </div>
                                </div>
                            </div>

                            <div class="row auto justify-content-md-center mb-5">
                                <div className='grey-border-bottom my-3'></div>
                            </div>

                            <div class="px-4 mb-5 d-flex">
                                <div className='row'>
                                    <div className='col-7 d-flex justify-content-md-between align-items-center'>
                                        <div>
                                            <h3 className='mb-5'>
                                                The Iterate Phase
                                            </h3>
                                            <p>
                                                In the CIC model's Iterate phase, teams progress through targeted quadrants that collectively refine a product concept into a user-validated prototype. This iterative approach ensures that each aspect of the product—from functionality to user experience—is rigorously developed and tested, resulting in a solution that's both innovative and practical.
                                                <br />
                                                <br />
                                                <span>
                                                    Quadrant-Focused Development: Segments iteration into specialized quadrants for focused enhancements
                                                </span>
                                                <br />
                                                <br />
                                                <span>
                                                    Rapid Prototyping: Converts ideas into prototypes swiftly for fast feedback and iterations.
                                                </span>
                                                <br />
                                                <br />
                                                <span>
                                                    Feedback Loops: Utilizes ongoing feedback to continuously improve features and user experience
                                                </span>
                                                <br />
                                                <br />
                                                <span>
                                                    Preparation for Scale: Readies the prototype for market launch, aligning with the Consolidate phase
                                                </span>
                                            </p>
                                        </div>
                                        <div class='section-2-divider'></div>
                                    </div>
                                    <div className='col-4 position-relative'>
                                        <img alt='The Iterate Phase' title='The Iterate Phase' className='img-fluid' src={researchImg4} />
                                    </div>
                                </div>
                            </div>

                            <div class="row auto justify-content-md-center mb-5">
                                <div className='grey-border-bottom my-3'></div>
                            </div>

                            <div class="px-4 mb-5 d-flex">
                                <div className='row'>
                                    <div className='col-7 d-flex justify-content-md-between align-items-center'>
                                        <div>
                                            <h3 className='mb-5'>
                                                Consolidate Phase
                                            </h3>
                                            <p>
                                                The Consolidate phase turns results into a plan of action.  It crystallizes your achievements, measures progress against KPIs, and provides a blueprint for taking your innovation from concept to market dominance. This phase empowers you with the data-driven insights you need to confidently transition into full-scale product development and launch.
                                                <br />
                                                <br />
                                                <span>
                                                    KPI Evaluation: Thoroughly assess your performance against the initial goals and metrics.
                                                </span>
                                                <br />
                                                <br />
                                                <span>
                                                    Scalability Analysis: Determine the resources and processes needed for full-scale product development
                                                </span>
                                                <br />
                                                <br />
                                                <span>
                                                    Launch Strategy: Develop a comprehensive plan for taking your innovation to market and achieving widespread adoption
                                                </span>
                                            </p>
                                        </div>
                                        <div class='section-2-divider'></div>
                                    </div>
                                    <div className='col-4 position-relative'>
                                        <img alt='The Iterate Phase' title='The Iterate Phase' className='img-fluid' src={researchImg5} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='d-block d-lg-none'>
                        <div class="row row-cols-1 row-cols-md-2 flex-wrap auto justify-content-center mb-5">
                            <div className='col-auto order-1'>
                                <Card cardIcon='mag-glass' cardStyle='card-outline' cardSize='card-860'>
                                    <h5 class="card-title my-4">
                                        Research & Methodology
                                    </h5>
                                    <p class="card-text">
                                        Our research methodology is built on a foundation of rigorous scientific inquiry. We leverage proprietary techniques and tools to uncover new patterns and trends in AI, AR, VR, and UX. We use this expertise to inform our innovation process, ensuring that every solution we develop meets your unique goals and objectives. Our CIC model consists of three stages: Concept, Iterate, and Consolidate.
                                    </p>
                                </Card>
                            </div>
                        </div>

                        <div class="row row-cols-1 row-cols-md-2 flex-wrap auto justify-content-center mb-5">
                            <div className='col-auto order-1'>
                                <Card cardIcon='flag' cardStyle='card-outline' cardSize='card-860'>
                                    <h5 class="card-title my-4">
                                        Purpose
                                    </h5>
                                    <p class="card-text">
                                        Actively contribute to the digital experience industry by identifying and promoting best practices. This initiative aims to foster the conscious and careful adoption of digital innovations, ensuring sustainable and impactful advancements.
                                        From exploring DX research methodologies to understanding neurological and physiological impacts, our approach encompasses a comprehensive journey. We delve into the intricacies of Design and Coding processes, aiming to deliver immersive experiences in the real world.
                                    </p>
                                </Card>
                            </div>
                        </div>

                        <div class="row row-cols-1 row-cols-md-2 flex-wrap auto justify-content-center">
                            <div class="mb-5 d-flex flex-column">
                                <h2 className='my-4'>
                                    Products
                                </h2>
                                <p>
                                    <span>
                                        Innovation for your Team
                                    </span>
                                    <br />
                                    Innovation process implementation for corporations
                                </p>
                            </div>

                            <div className='d-flex flex-column'>
                                <h3 className='mb-4'>
                                    ARms
                                </h3>
                                <p>
                                    At DX Labs, we believe that innovation is not just about technology, but about driving tangible results for your business. That's why we've developed the CIC Framework, a structured approach to innovation that combines cutting-edge AI research with human-centered design principles. Our CIC model is designed to help you achieve breakthroughs and stay ahead of the competition by providing a clear roadmap for success.
                                </p>
                            </div>

                            <div className='grey-border-bottom my-3'></div>

                            <div className='d-flex flex-column'>
                                <h3 className='mb-4'>
                                    VitalSense
                                </h3>
                                <p>
                                    VitalSense is a platform designed to enhance the understanding and evaluation of digital user experiences. If you have developed or are currently working on a VR project, we can assist you in optimizing both adoption and outcomes through our comprehensive user testing process.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="container-fluid section-margin research-section-3">
                <div className="container">

                    <CardContact>
                        Empower Your Innovation Project Get In Touch!
                    </CardContact>

                </div>
            </div>

            <Footer />
        </>
    );
}

export default Research;