import React from 'react';
import { Button } from './button';
import './cardContact.css';

export const CardContact = ({ children }) => {
    return (
        <div className={`card-contact`}>
            <div className={`card-body p-0`}>
                <form class="row justify-content-md-around g-3">
                    <div class="col-12 col-lg-4">
                        {children}
                    </div>
                    <div class="col-auto">
                        <input type="email" class="form-control mb-4" id="contactEmail" placeholder="Enter your email..." />
                        <Button type='submit' buttonStyle='btn-primary'>Contact Us</Button>
                    </div>
                </form>
            </div>
        </div>
    );
}