import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Card } from '../card';
import { ImageCard } from '../imageCard';
import { Button } from '../button';
import Footer from '../footer';
import useScreenSize from '../../useScreenSize';
import './home.css';
import '../../App.css';
import imgSection2 from '../../images/img-section-2.png';
import imgPablo from '../../images/home-pablo.png';
import imgPabloMb from '../../images/home-pablo-mb.png';
import imgPreston from '../../images/home-preston.png';
import imgPrestonMb from '../../images/home-preston-mb.png';

function Home() {
    const screenSize = useScreenSize();
    const [pablo, setPablo] = useState();
    const [preston, setPreston] = useState();
    useEffect(() => {
        if (screenSize.width > 991) {
            setPablo(imgPablo);
            setPreston(imgPreston);
        } else {
            setPablo(imgPabloMb);
            setPreston(imgPrestonMb);
        }
    });

    return (
        <>
            <div class="container-fluid section-margin section-1">
                <div className="container">

                    <div style={{ marginBottom: 7 + 'rem' }} class="row">
                        <div class="card card-home-1">
                            <div class="card-body">
                                <h5 class="card-title mt-3 mb-3 mb-lg-5">Elevate Your Digital Journey</h5>
                                <h6 class="card-subtitle mb-3 mb-lg-4">At DX Labs, we use AI, AR, VR, and user-centric design to create digital experiences.</h6>
                                <p class="card-text mb-3 mb-lg-5">Our experts use advanced technologies and industry knowledge to help you navigate the digital landscape and find growth opportunities.</p>
                                <div class="btn-container">
                                    <Button link='/contact' buttonStyle='btn-primary'>Contact Us</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row row-cols-1 row-cols-lg-3 auto justify-content-between">
                        <div className='col d-flex justify-content-center mb-4 mb-lg-0'>
                            <Card cardIcon='science' cardStyle='card-outline' cardSize='card-400'>
                                <h5 class="card-title my-4">Vital Sense</h5>
                                <p class="card-text">VitalSense analyzes user bio-data for emotion and behavior insights, enhancing VR/AR experiences.</p>
                                <HashLink to='/products#vitalSense' className='card-link'>Discover VitalSense &gt;</HashLink>
                            </Card>
                        </div>

                        <div className='col d-flex justify-content-center  mb-4 mb-lg-0'>
                            <Card cardIcon='ar' cardStyle='card-outline' cardSize='card-400'>
                                <h5 class="card-title my-4">ARms</h5>
                                <p class="card-text">ARms, a web-based platform, offers custom AR experiences from a 3D model library, via QR codes, for all.</p>
                                <HashLink to='/products#arms' className='card-link'>Discover ARms &gt;</HashLink>
                            </Card>
                        </div>

                        <div className='col d-flex justify-content-center  mb-4 mb-lg-0'>
                            <Card cardIcon='vr' cardStyle='card-outline' cardSize='card-400'>
                                <h5 class="card-title my-4">TONI</h5>
                                <p class="card-text">Toni, an AI chat system, uses RAG to provide detailed service info, customizable for various engagement strategies and businesses.</p>
                                <Link to='/products' className='card-link'>Discover AI Systems &gt;</Link>
                            </Card>
                        </div>
                    </div>

                </div>
            </div>

            <div class="container-fluid section-margin section-2">
                <div className="container">

                    <div class="row row-cols-1 row-cols-md-2 auto justify-content-center">
                        <div className='col-12 col-lg-4 position-relative'>
                            <img alt='Services Provided' title='Services Provided' className='img-fluid' src={imgSection2} />
                            <h2 class='section-2-title'>
                                <span>
                                    Services
                                </span>
                                <br />
                                Provided
                            </h2>
                        </div>
                        <div className='col-12 col-lg-7 mt-5 mt-lg-0 d-flex justify-content-between'>
                            <div class='section-2-divider'></div>
                            <div>
                                At DX Labs, we blend the best of scientific research and user-focused design to create meaningful solutions through our CIC Framework (Concept, Iterate, Consolidate).
                                <br />
                                <br />
                                Combining the latest AI advancements with a deep understanding of human needs brings structure and precision to the entire ideation, creation, and building process. Our approach goes beyond seamless implementation; we're committed to helping our customers envision and navigate the following steps on their journey.

                                <div className='mt-5'>
                                    <Button link='/services' buttonStyle='btn-primary'>Learn More</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="container-fluid section-margin section-3">
                <div className="container">

                    <div class="row justify-content-md-center">
                        <h2 class='section-3-title'>
                            <span>
                                Driving Innovation:
                            </span>
                            Introducing the CIC Model a Rapid Innovation Framework Leveraging AI
                        </h2>
                        <div className='mt-5 d-flex justify-content-center'>
                            <Button link='/services' buttonStyle='btn-primary'>Learn More</Button>
                        </div>
                    </div>

                </div>
            </div>

            <div class="container-fluid section-margin section-4">
                <div className="container">

                    <div class="row row-cols-3 auto justify-content-center">
                        <div className='col-auto mb-3 mb-lg-0'>
                            <Card cardIcon='info' cardStyle='card-outline' cardSize='card-860'>
                                <h5 class="card-title my-4">Who we are</h5>
                                <p class="card-text">Tonic3's DX Labs is rooted in our parent company's commitment to user engagement and digital solutions since 1995. With nearly three decades of experience, we've helped large organizations develop websites, applications, and customer support teams. Our strategic shift to DX Labs highlights our dedication to pushing digital boundaries and embracing innovation, with a focus on the human side of connected digital experiences.</p>
                                <div className='mb-4'>
                                    <Button link='/about' buttonStyle='btn-primary'>Learn More</Button>
                                </div>
                            </Card>
                        </div>

                        <div className='col-auto mb-3 mb-lg-0'>
                            <ImageCard cardSize='card-190'>
                                <img alt='Pablo Sigler - Acting CEO' title='Pablo Sigler - Acting CEO' className='w-100 rounded float-end' src={pablo} />
                                <div className='text-container'>
                                    <h5 class='title'>Pablo Sigler</h5>
                                    <p class='text'>Acting CEO</p>
                                </div>
                            </ImageCard>
                        </div>

                        <div className='col-auto mb-3 mb-lg-0'>
                            <ImageCard cardSize='card-190'>
                                <img alt='Preston McCauley - Director Of Innovation Labs' title='Preston McCauley - Director Of Innovation Labs' className='w-100 rounded float-end' src={preston} />
                                <div className='text-container'>
                                    <h5 class='title'>Preston McCauley</h5>
                                    <p class='text'>Director Of Innovation Labs</p>
                                </div>
                            </ImageCard>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    );
}

export default Home;